.topnav {
  background-color: #FFFEF8ED;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 5;
  border-bottom: 2px solid var(--dark-blue);
}

.topnav a{
  float: left;
  display: block;
  text-align: center;
  text-decoration: none;
  font-family: "Trirong", serif;
  font-weight: 600;
}

.icon{
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 2rem;
}

.icon img {
  height: 2rem;
}

.page {
  position: relative;
  color: var(--dark-blue);
  font-size: 1.3rem;
  padding: 1rem 1.4rem;
}

.page:after,
.dropdown-page:after {
  right: 0;
}

.page:before,
.dropdown-page:before {
  left: 0;
}

.active {
  background-color: var(--pink);
  color: var(--white);
  font-style: italic;
}

.page:hover:after,
.page:hover:before,
.page:focus:after,
.page:focus:before,
.dropdown-page:hover:after,
.dropdown-page:hover:before,
.dropdown-page:focus:after,
.dropdown-page:focus:before  {
  width: 50%;
  opacity: 1;
}

.page:hover,
.page:focus,
.dropdown-page:hover,
.dropdown-page:focus {
  color: var(--pink)
}

.page.active:hover,
.page.active:focus,
.dropdown-page.active:hover,
.dropdown-page.active:focus {
  color: var(--dark-blue)
}

.dropdown-button {
  display: none;
}

.dropdown {
  display: none;
}

@media only screen and (max-width: 600px) {
  .topnav a:not(.icon, .dropdown-page) {
    display: none;
  }

  .dropdown-button {
    display: block;
    position: absolute;
    right: 0;
    background: transparent;
    margin: 0.4rem 1rem;
    font-size: 1.75rem;
    border: none;
    color: var(--pink)
  }

  .dropdown {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 3.4rem;
    right: 0;
    background-color: #fefcf6ed;
    border-bottom: 2px solid var(--dark-blue);
    border-top: 2px solid var(--dark-blue);
  }

  .dropdown-page {
    position: relative;
    color: var(--dark-blue);
    font-size: 1.25rem;
    width: 100vw;
    padding: 1.25rem 0;
    text-align: center;
    
  }
}



