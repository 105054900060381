.App {
  display: block;
  width: 85%;
  margin: 15vw auto 10vw;
}

@media only screen and (max-width: 800px) {
  .App {
    max-width: 600px;
  }

}

@media only screen and (max-width: 700px) {
  .heading {
   margin-top: 45%;
  }

    .App {
      max-width: 95%;
    }

}

@media only screen and (min-width: 601px) {
  .heading {
    margin-top: 175px;
  }

}

.heading {
  margin-bottom: 50px;
  margin-left: 10%;
}

.content {
  margin-left: 10%;
}
