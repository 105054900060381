.card{
  width: 80%;
  padding: 30px;
  border-radius: 10px;
  color: #fff;
  display: block;
  position: relative;
  background: #f00;
  margin-bottom: 20px;
  margin-left: 10%;
  outline: 3px dashed var(--dark-blue);
}

.timeline-card:before{
  content: '';
  position: absolute;
  left: -9.7vw;
  top: 50%;
  transform: translateY(-50%);
  width: max(2.5vw, 1rem);
  height: max(2.5vw, 1rem);
  border: 5px solid var(--dark-blue);
  border-radius: 50%;
}

.timeline-card .card-body:before{
  content: '';
  position: absolute;
  left: -8.2vw;
  top: 0;
  width: 0px;
  height: 150%;
  border: 2px solid var(--dark-blue);
  z-index: -1;
}

@media only screen and (max-width: 650px) {
  .card {
    margin-left: 5%;
  }
  .timeline-card:before {
    visibility: hidden;
  }
  .timeline-card .card-body:before{
    visibility: hidden;
  }
}

.card-title {
  font-size: 30px;
  margin-bottom: 20px;
}

.link-card-body {
  text-decoration: none;
}

.responsibility {
  padding-left: 18px;
}

.card-body h4 {
  margin-top: 5px;
  font-size: 1.25rem;
  font-weight: 100;
}

.card-body h2 {
  font-weight: 600;
  margin-bottom: 0px;
}

