.home-container {
  display: grid;
  grid-template-rows: auto;
  column-gap: 10%;
  row-gap: 0%;
  align-items: center;
  grid-template-columns: 100%;
  grid-template-areas:
    "img"
    "intro-container"
    "footer";
  margin-top: 20%;
}

.home-container h1 {
  font-weight: 100;
  color: var(--dark-blue);
  margin-top: 0px;
  margin-bottom: 0px;
  align-self: flex-start;
  font-size: 10rem;
  line-height: 11rem;
}

.home-container p {
  color: var(--black);
}

.home-container .footer {
  grid-area: footer;
}


@media only screen and (max-width: 700px) {
  .greeting img {
    max-width: 500px;
  }

  .home-container {
    margin-top: 45%;
  }

  .home-container p {
    margin-top: 0;
  }
}

.intro-container {
  grid-area: intro-container;
  display: grid;
  grid-template-columns: 65% 30%;
  column-gap: 5%;
}

.intro-container p {
  font-size: 1.5rem;
}

.greeting {
  grid-area: img;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5%;
}

.greeting img {
  width: 100%;
  animation-name: fade-in-content;
  animation-duration: 1.5s;
  max-height: 65%;
}

@keyframes fade-in-content {
  from {opacity: 0;}
  to {opacity: 1;}
}

.link-container {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  flex-direction: column;
}

.social-link {
  display: inline;
  text-decoration: none;
  font-family: "Trirong", serif;
  font-weight: 600;
  font-size: 1.5rem;
  margin: 1vw;
  color: var(--pink);
  border: 2px solid var(--pink);
  padding: 1rem;
  border-radius: 8px;
  width: 80%;
  text-align: center;
  height: fit-content;
}

.social-link:hover,
.social-link:focus {
  background: var(--pink);
  color: var(--white);
  transition: all 0.2s ease-in-out;
}

@media only screen and (min-width: 1250px) {
  .intro-container p {
    font-size: 1.5rem;
  }
  .link-container a {
    font-size: 1.75rem;
  }
}

@media only screen and (max-width: 900px) {
  .home-container h1 {
    font-size: 8rem;
    line-height: 9rem;
  }
}


@media only screen and (max-width: 700px) {
  .intro-container {
    grid-template-columns: 100%;
  }

  .home-container h1 {
    font-size: 6rem;
    line-height: 7rem;
    align-self: center;
  }
}

@media only screen and (max-width: 500px) {
  .home-container h1 {
    font-size: 5rem;
    line-height: 6rem;
    text-align: center
  }

  .intro-container p {
    margin-left: 1rem;
  }
}

@media only screen and (max-width: 400px) {
  .home-container h1 {
    font-size: 4rem;
    line-height: 5rem;
  }
}

@media only screen and (max-width: 350px) {
  .link-container {
    flex-direction: column;
    align-items: center;
  }

  .link-container a {
    margin-bottom: 1rem;
  }
}
