:root {
  --black: #143349;
  --white: #fffffc;
  --gray: #89898a;
  --pink: #E0B18F;
  --dark-blue: #1f4f70;
}

body {
  margin: 0;
  font-family: "Jost", sans-serif;
    font-optical-sizing: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--white);
  color: var(--black);
}

h1, h2, h4 {
  font-family: "Trirong", serif;
  font-weight: 600;
}

h1 {
  font-size: 5rem;
}

h2 {
  font-size: 2.5rem;
  font-weight: 100;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.5rem;
  font-style: italic;
}

p, li {
  font-weight: 300;
  font-size: 1.15rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
