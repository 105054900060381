.project-card {
  display: flex;
  grid-gap: 5%;
  align-items: center;
  padding-bottom: 40px;
  border-bottom: 2px var(--dark-blue) dashed;
}

@media only screen and (max-width: 600px) {
  .project-card {
    flex-direction: column;
  }
}

.see-more-link {
  text-decoration: none;
}

.see-more {
  outline: 2px var(--pink) solid;
  color: var(--pink);
  padding: 7px;
  border-radius: 5px;
  width: fit-content;
}