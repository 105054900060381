.timeline{
  display: block;
  width: 80%;
  margin: 50px auto;
}


.card:nth-child(2) .card-body:before{
  top: -25vh;
  height: 125vh;
}

@media only screen and (max-width: 450px) {
  .card:nth-child(2) .card-body:before{
    top: -70vh;
    height: 160vh;
  }

}

.card:nth-child(7) .card-body:before{
  height: 75vh;
}

.card:after{
  content: '';
  position: absolute;
  left: -8.5%;
  top: 50%;
  transform: translateY(-50%);
  width: 7%;
  height: 2px;
  background: #fff;
  z-index: -1;
}

.card:nth-child(odd){
  background: var(--white);
  color: var(--black);
}

.card:nth-child(odd):before{
  background: var(--pink)
}

.card:nth-child(even){
  background: var(--white);
  color: var(--black);
}

.card:nth-child(even):before{
  background: var(--white);
}
